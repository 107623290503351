import Usa from '../assets/img/usa.svg'
import MapMiami from '../assets/img/map-miami.svg'
import MapAustin from '../assets/img/map-austin.svg'
import MapVegas from '../assets/img/map-vegas.svg'
import MapChicago from '../assets/img/map-chicago.svg'
import MapNashville from '../assets/img/map-nashville.svg'
import MapNewYork from '../assets/img/map-new-york.svg'
import MapAngeles from '../assets/img/map-angeles.svg'
import MapSanDiego from '../assets/img/map-san-diego.svg'
import MapBoth from '../assets/img/map-both.svg'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import WorldMap from '../assets/img/world.svg'

const UsaMap = ({ selected, mdDown }) => {
    const isInternational = selected.includes('Barcelona') || selected.includes('London') || selected.includes('Cancun')
    console.log(isInternational)
    return (
        <div style={{ position: 'relative', width: '100%', minWidth: '50vw', height: mdDown ? '40vh' : '100vh', display: 'flex', justifyContent: 'center' }}>
            {(selected.length === 0 || isInternational) && <img src={WorldMap} alt='World Map' style={{ display: 'block', height: mdDown ? '40vh' : '100vh', position: 'absolute', left: '0%' }} />}
            {selected.includes('Miami') && !isInternational && <img src={MapMiami} alt='USA Map' style={{ display: 'block', height: mdDown ? '40vh' : '100vh', position: 'absolute', left: '25%' }} />}
            {selected.includes('Chicago') && !isInternational && <img src={MapChicago} alt='USA Map' style={{ display: 'block', height: mdDown ? '40vh' : '100vh', position: 'absolute', left: '25%' }} />}
            {selected.includes('Las Vegas') && !isInternational && <img src={MapVegas} alt='USA Map' style={{ display: 'block', height: mdDown ? '40vh' : '100vh', position: 'absolute', left: '25%' }} />}
            {selected.includes('Nashville') && !isInternational && <img src={MapNashville} alt='USA Map' style={{ display: 'block', height: mdDown ? '40vh' : '100vh', position: 'absolute', left: '25%' }} />}
            {selected.includes('Austin') && !isInternational && <img src={MapAustin} alt='USA Map' style={{ display: 'block', height: mdDown ? '40vh' : '100vh', position: 'absolute', left: '25%' }} />}
            {selected.includes('New York') && !isInternational && <img src={MapNewYork} alt='USA Map' style={{ display: 'block', height: mdDown ? '40vh' : '100vh', position: 'absolute', left: '25%' }} />}
            {selected.includes('Los Angeles') && !selected.includes('San Diego') && !isInternational && <img src={MapAngeles} alt='USA Map' style={{ display: 'block', height: mdDown ? '40vh' : '100vh', position: 'absolute', left: '25%' }} />}
            {selected.includes('San Diego') && !selected.includes('Los Angeles') && !isInternational && <img src={MapSanDiego} alt='USA Map' style={{ display: 'block', height: mdDown ? '40vh' : '100vh', position: 'absolute', left: '25%' }} />}
            {selected.includes('San Diego') && selected.includes('Los Angeles') && !isInternational && <img src={MapBoth} alt='USA Map' style={{ display: 'block', height: mdDown ? '40vh' : '100vh', position: 'absolute', left: '25%' }} />}
        </div>
    )
}

export default UsaMap