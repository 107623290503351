import StepImage from '../assets/img/step.svg'
import StepImage2 from '../assets/img/step-2.svg'
import StepImage3 from '../assets/img/step-3.svg'
import StepImage4 from '../assets/img/step-4.svg'
import StepImage5 from '../assets/img/step-5.svg'
import StepImage6 from '../assets/img/step-6.svg'
import BackStep from '../assets/img/back-step.svg'
import NextStep from '../assets/img/forward-step.svg'
import CurrentStep from '../assets/img/current-step.svg'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'

const Steps = ({ step }) => {
    const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'))
    const navigate = useNavigate()
    const handleStepLine = () => {
        switch (step) {
            case 1:
                return StepImage
            case 2:
                return StepImage2
            case 3:
                return StepImage3
            case 4:
                return StepImage4
            case 5:
                return StepImage5
            case 6:
                return StepImage6
            default:
                return StepImage
        }
    }
    const handleStepImage = (image) => {
        if (image < step) {
            return BackStep
        }
        if (image === step) {
            return CurrentStep
        }
        if (image > step) {
            return NextStep
        }
    }
    return (
        <div style={{
            height: 40,
            width: mdDown ? '90%' : '70%',
            marginTop: mdDown ? '5%' : '0%',
            marginBottom: mdDown ? '7%' : '3%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }}
        >
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', position: 'relative', top: '10%' }}>
                <img
                    src={handleStepImage(1)}
                    alt='Step Icon'
                    style={{ cursor: 1 < step ? 'pointer' : 'auto' }}
                    onClick={1 < step ? () => navigate('/select-city') : null}
                />
                <img
                    src={handleStepImage(2)}
                    alt='Step Icon'
                    style={{ cursor: 2 < step ? 'pointer' : 'auto' }}
                    onClick={2 < step ? () => navigate('/select-month') : null}
                />
                <img
                    src={handleStepImage(3)}
                    alt='Step Icon'
                    style={{ cursor: 3 < step ? 'pointer' : 'auto' }}
                    onClick={3 < step ? () => navigate('/select-nights') : null}
                />
                <img
                    src={handleStepImage(4)}
                    alt='Step Icon'
                    style={{ cursor: 4 < step ? 'pointer' : 'auto' }}
                    onClick={4 < step ? () => navigate('/select-accommodation') : null}
                />
                <img
                    src={handleStepImage(5)}
                    alt='Step Icon'
                    style={{ cursor: 5 < step ? 'pointer' : 'auto' }}
                    onClick={5 < step ? () => navigate('/select-colleagues') : null}
                />
                <img
                    src={handleStepImage(6)}
                    alt='Step Icon'
                    style={{ cursor: 6 < step ? 'pointer' : 'auto' }}
                    onClick={6 < step ? () => navigate('/additional-services') : null}
                />
            </div>
            <img src={handleStepLine()} alt='steps' height='40' style={{ width: mdDown ? '94%' : step === 1 || step === 6 ? '95%' : '97%', position: 'relative', left: step === 1 ? 3 : 'auto', right: step === 6 ? 3 : 0 }} />
        </div>
    )
}

export default Steps