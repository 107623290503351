import { Box, Grid, Typography, useMediaQuery, FormGroup, FormControlLabel, Checkbox, TextField, InputAdornment } from '@mui/material'
import Logo from '../assets/logo/offsiteio-logo.svg'
import { useState, useEffect, useContext } from 'react'
import Airplanes2 from '../assets/img/airplanes2.svg'
import ServicesIllustration from '../assets/img/services.svg'
import ServicesIllustration2 from '../assets/img/services-2.svg'
import ServicesIllustration3 from '../assets/img/services-3.svg'
import ServicesIllustration4 from '../assets/img/services-4.svg'
import ServicesIllustration5 from '../assets/img/services-5.svg'
import Button from '../components/Button'
import { useNavigate } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import { Context } from '../Context'
import { showToast } from '../utils/showToast'
import axios from 'axios'
import Steps from '../components/Steps'
import Plane3 from '../assets/img/plane-3.svg'
import Plane4 from '../assets/img/plane-4.svg'

const options = [
    "Team Building Exercises",
    "Motivational / Guest Speaker",
    "Wellness Activities",
    "Charity Activities",
    "City Tours / Cultural Outings",
    "Nightclub Experience",
    "Cocktail Party"
]

const images = [
    ServicesIllustration,
    ServicesIllustration2,
    ServicesIllustration3,
    ServicesIllustration4,
    ServicesIllustration5
]

const AdditionalServices = () => {
    const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'))
    const [name, setName, email, setEmail, city, setCity, month, setMonth, nights, setNights, hotelClass, setHotelClass, colleagues, setColleagues, additionalServices, setAdditionalServices] = useContext(Context)
    const navigate = useNavigate()
    const [otherIdea, setOtherIdea] = useState('')
    const [selected, setSelected] = useState(additionalServices && additionalServices.length ? additionalServices : [])
    const [trigger, setTrigger] = useState(false)
    const [selectedImage, setSelectedImage] = useState(images[0])
    const [loading, setLoading] = useState(false)
    const styles = {
        logo: {
            margin: '3%',
            marginBottom: '1%',
            alignSelf: 'flex-end',
            width: '10%'
        },
        steps: {
            width: mdDown ? '90%' : '60%',
            marginTop: mdDown ? '5%' : '0%',
            marginBottom: mdDown ? '0%' : '5%'
        },
        titleMobile: {
            position: 'absolute',
            top: '3%',
            width: '90%',
        }
    };

    const handleSelect = (opt) => {
        const currentSelected = selected
        let newSelected = []
        if (currentSelected.includes(opt)) {
            newSelected = currentSelected.filter(el => el !== opt)
            setSelected(newSelected)
            setAdditionalServices(newSelected)
            if (currentSelected.length <= 5) {
                setSelectedImage(images[currentSelected.length - 1])
            } else if (currentSelected.length > 5) {
                setSelectedImage(images[4])
            } else {
                setSelectedImage(images[0])
            }
        } else {
            currentSelected.push(opt)
            setSelected(currentSelected)
            setAdditionalServices(currentSelected)
            if (currentSelected.length <= 5) {
                setSelectedImage(images[currentSelected.length - 1])
            } else if (currentSelected.length > 5) {
                setSelectedImage(images[4])
            } else {
                setSelectedImage(images[0])
            }
        }
        setTrigger(!trigger)
    }

    const handleOtherIdea = (value) => {
        setOtherIdea(value)
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        const currentServices = additionalServices
        if (otherIdea) currentServices.push(otherIdea)
        setAdditionalServices(currentServices)
        if (additionalServices.length > 0 || otherIdea) {
            const url = 'https://script.google.com/macros/s/AKfycbw6_yMVWGF7zwcip_LgAc9jS9FGoC3PlJ7XCxgdGE5VIMo_049xeon44TbJQhtggqg03g/exec'
            let date = new Date();
            let datestring = ("0" + (date.getMonth() + 1).toString()).substr(-2) + "/" + ("0" + date.getDate().toString()).substr(-2)  + "/" + (date.getFullYear().toString()).substr(2);
            let formData = new FormData();
            formData.append('date', datestring);
            formData.append('name', name);
            formData.append('email', email);
            formData.append('cities', city);
            formData.append('month', month);
            formData.append('accommodation', hotelClass);
            formData.append('nights', nights);
            formData.append('colleagues', colleagues);
            formData.append('services', additionalServices);

            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }

            axios.post(url, formData, config)
                .then((response) => {
                    if (response.status === 200) {
                        setLoading(false)
                        navigate('/thanks')
                    } else {
                        setLoading(false)
                        showToast('error', 'Something went wrong, please try again')
                    }
                })
                .catch(error => {
                    console.error(error)
                    setLoading(false)
                    showToast('error', 'Something went wrong, please try again')
                }
                )
        } else {
            showToast('error', 'Please select an option or type a service you would like to ask for')
        }

    }

    useEffect(() => { }, [selected.length, trigger])

    useEffect(() => {
        if (!name || !email || !city || !month || !nights || !hotelClass || !colleagues) {
            navigate('/')
        }
    }, [])

    return (
        <Grid container style={{
            height: mdDown ? '100%' : '100vh',
            minHeight: '100vh',
            overflow: 'hidden',
        }}>
            <Grid item md={7} xs={12} style={{ backgroundColor: '#EFC2CB', height: mdDown ? '40vh' : '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}>
                <CSSTransition
                    key={selected}
                    classNames='fade'
                    in={true}
                    appear={true}
                    timeout={200}
                >
                    <img src={selectedImage} alt='Services Illustration' style={{ width: selectedImage === ServicesIllustration ? '40%' : '80%' }} />
                </CSSTransition>
                {mdDown &&
                    <Typography variant="h2" component="h2" gutterBottom style={styles.titleMobile}>
                        Can we help with any additional services to make your offsite more awesome?
                    </Typography>
                }
            </Grid>
            <Grid item md={5} xs={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', minHeight: mdDown ? '60vh' : '100vh' }}>
                {!mdDown && <img src={Logo} alt='Offsiteio Logo' style={styles.logo} />}
                <Steps mdDown={mdDown} step={6} />
                <Box style={{ width: mdDown ? '90%' : '70%', display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: '5%', height: mdDown ? 'auto' : '60vh', minHeight: mdDown ? 0 : '60vh', zIndex: 5 }}>
                    {!mdDown && <Typography variant="h3" component="h3" gutterBottom>
                        Can we help with any additional services to make your offsite more awesome?
                    </Typography>}
                    <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', paddingTop: mdDown ? '3%' : '0%' }}>
                        <Box sx={{ m: 0, mb: 0, mt: mdDown ? 0 : 2, width: '100%', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                            <FormGroup sx={{ maxHeight: mdDown ? 'none' : '35vh' }}>
                                {options.map(opt => (
                                    <FormControlLabel
                                        key={opt}
                                        control={
                                            <Checkbox
                                                checked={selected[opt]}
                                                onChange={() => handleSelect(opt)}
                                            />
                                        }
                                        label={
                                            <Typography sx={{ fontWeight: 600, mt: '2px' }} gutterBottom>
                                                {opt}
                                            </Typography>
                                        }
                                    />
                                ))}

                            </FormGroup>
                        </Box>
                        <Typography sx={{ fontWeight: 700, mt: '10px' }} gutterBottom>Other ideas?</Typography>
                        <TextField
                            size="medium"
                            sx={{ marginTop: '2%', width: '100%' }}
                            onChange={e => handleOtherIdea(e.target.value)}
                            value={otherIdea}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" />
                                )
                            }}
                            placeholder={' Type here and we can discuss'}
                            type='text'
                        />
                    </Box>
                    <form
                        method='POST'
                        action='https://script.google.com/macros/s/AKfycbw6_yMVWGF7zwcip_LgAc9jS9FGoC3PlJ7XCxgdGE5VIMo_049xeon44TbJQhtggqg03g/exec'
                        onSubmit={onSubmit}
                        style={{
                            width: '100%',
                        }}
                    >
                        <Button
                            text='Submit'
                            styles={{
                                marginTop: '7%',
                                width: '100%',
                                height: '3.5rem',
                                minHeight: '3.5rem'
                            }}
                            loading={loading}
                            type='submit'
                        />

                    </form>
                </Box>
                <img src={Airplanes2} alt='Airplanes Illustration' className="zoom-in-zoom-out" style={{ width: '100%', position: 'relative', bottom: 0 }} />
                <img src={Plane3} alt='plane illustration' className='plane' width={40} style={{position: 'absolute', bottom: mdDown ? '7%' : '11%', right: mdDown ? '70%' : '30%'}} />
                <img src={Plane4} alt='plane illustration' className='plane-2' width={40} style={{position: 'absolute', bottom: mdDown ? '6%' : '14%', right: mdDown ? '17%' : '10%'}} />
            </Grid>
        </Grid>
    )
}

export default AdditionalServices