import { toast } from 'react-toastify'

export const showToast = (toastType, msg) => {
    const options = {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
    }
    switch (toastType) {
        case 'error':
            return toast.error(msg, options)
        case 'success':
            return toast.success(msg, options)
        case 'warning':
            return toast.warning(msg, options)
        case 'info':
            return toast.info(msg, options)
        default:
            return toast.info(msg, options)
    }
}