import './App.css';
import ThemeProvider from './theme/ThemeProvider';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './pages/Home'
import Cities from './pages/Cities'
import Months from './pages/Months';
import Nights from './pages/Nights';
import Colleagues from './pages/Colleagues'
import HotelClass from './pages/HotelClass';
import AdditionalServices from './pages/AdditionalServices';
import Thanks from './pages/Thanks';
import NotFound from './pages/NotFound';
import { injectStyle } from 'react-toastify/dist/inject-style'
import { ToastContainer } from 'react-toastify'

if (typeof window !== 'undefined') {
  injectStyle()
}


function App() {
  return (
    <ThemeProvider>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route
              path='/'
              element={
                <Home />
              }
          />
          <Route
              path='/home'
              element={
                <Home />
              }
          />
          <Route
              path='/select-city'
              element={
                <Cities />
              }
          />
          <Route
              path='/select-month'
              element={
                <Months />
              }
          />
          <Route
              path='/select-nights'
              element={
                <Nights />
              }
          />
          <Route
              path='/select-accommodation'
              element={
                <HotelClass />
              }
          />
          <Route
              path='/additional-services'
              element={
                <AdditionalServices />
              }
          />
          <Route
              path='/select-colleagues'
              element={
                <Colleagues />
              }
          />
          <Route
              path='/thanks'
              element={
                <Thanks />
              }
          />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
