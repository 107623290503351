import { Box, Grid, Typography, useMediaQuery, Button, Paper } from '@mui/material'
import Logo from '../assets/logo/offsiteio-logo.svg'
import { useState } from 'react'
import Airplanes from '../assets/img/airplanes.svg'
import Airplanes2 from '../assets/img/airplanes2.svg'
import CityImage from '../assets/img/city.jpg'
import BeerImage from '../assets/img/beer.jpg'
import MiamiImage from '../assets/img/miami.jpg'
import BarImage from '../assets/img/bar.jpg'
import Carousel from 'react-material-ui-carousel'
import { useNavigate } from 'react-router-dom'
import Plane from '../assets/img/plane.svg'
import Plane2 from '../assets/img/plane-2.svg'
import Plane3 from '../assets/img/plane-3.svg'
import Plane4 from '../assets/img/plane-4.svg'

const Thanks = () => {
    const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const navigate = useNavigate()
    const [fullName, setFullName] = useState(null)
    const [email, setEmail] = useState(null)
    const styles = {
        paperContainer: {
            height: mdDown ? '40vh' : '100vh',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: '0px',
        },
        backgroundImage1: {
            backgroundImage: `url(${CityImage})`,
        },
        backgroundImage2: {
            backgroundImage: `url(${BeerImage})`,
        },
        backgroundImage3: {
            backgroundImage: `url(${MiamiImage})`,
        },
        backgroundImage4: {
            backgroundImage: `url(${BarImage})`,
        },
        logo: {
            margin: '3%',
            marginBottom: '2%',
            alignSelf: 'flex-end',
            width: '12%'
        }
    };

    const onSubmit = async () => {
        navigate('/')
    }

    return (
        <Grid container style={{
            height: mdDown ? '100%' : '100vh',
            minHeight: '100vh',
            overflow: 'hidden',
        }}>
            <Grid item md={7} xs={12} style={{ backgroundColor: '#F04B6C', height: mdDown ? '40vh' : '100vh' }}>
                <Carousel
                    autoPlay
                    infiniteLoop
                    showThumbs={false}
                    indicators={false}
                    interval={5000}
                >
                    <Paper style={Object.assign({}, styles.paperContainer, styles.backgroundImage1)}>
                        <img src={Logo} alt='Offsiteio Logo' width={mdDown ? 80 : 150} height={mdDown ? 80 : 150} />
                    </Paper>
                    <Paper style={Object.assign({}, styles.paperContainer, styles.backgroundImage2)}>
                        <img src={Logo} alt='Offsiteio Logo' width={mdDown ? 80 : 150} height={mdDown ? 80 : 150} />
                    </Paper>
                    <Paper style={Object.assign({}, styles.paperContainer, styles.backgroundImage3)}>
                        <img src={Logo} alt='Offsiteio Logo' width={mdDown ? 80 : 150} height={mdDown ? 80 : 150} />
                    </Paper>
                    <Paper style={Object.assign({}, styles.paperContainer, styles.backgroundImage4)}>
                        <img src={Logo} alt='Offsiteio Logo' width={mdDown ? 80 : 150} height={mdDown ? 80 : 150} />
                    </Paper>
                </Carousel>
            </Grid>
            <Grid item md={5} xs={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                {!mdDown && 
                <>
                    <img src={Airplanes} alt='Airplanes Illustration' className="zoom-in-zoom-out" style={{ width: '100%', marginTop: '-75px' }} />
                    <img src={Plane} alt='plane illustration' className='plane' width={40} style={{position: 'absolute', top: '5%', right: '30%'}} />
                    <img src={Plane2} alt='plane illustration' className='plane-2' width={40} style={{position: 'absolute', top: '4%', right: '10%'}} />
                </>
                }

                <Box style={{ width: mdDown ? '90%' : '60%', display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: '5%' }}>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="h2" component="h2" gutterBottom>
                            You’ve already taken care of most of the work required, we’ll take care of the rest!
                        </Typography>
                    </Box>
                    <Button
                        variant="contained"
                        sx={{
                            marginTop: '20%',
                            width: '100%',
                            height: '3.5rem',
                            minHeight: '3.5rem'
                        }}
                        onClick={onSubmit}
                    >
                        Back Offsiteio.com
                    </Button>
                </Box>
                <img src={Airplanes2} alt='Airplanes Illustration' className="zoom-in-zoom-out" style={{ width: '100%', position: 'relative', bottom: 0 }} />
                <img src={Plane3} alt='plane illustration' className='plane' width={40} style={{position: 'absolute', bottom: mdDown ? '7%' : '11%', right: mdDown ? '70%' : '30%'}} />
                <img src={Plane4} alt='plane illustration' className='plane-2' width={40} style={{position: 'absolute', bottom: mdDown ? '6%' : '14%', right: mdDown ? '17%' : '10%'}} />
            </Grid>
        </Grid>
    )
}

export default Thanks