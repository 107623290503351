import { Box, Grid, Typography, useMediaQuery, Slider } from '@mui/material'
import Logo from '../assets/logo/offsiteio-logo.svg'
import { useState, useContext, useEffect } from 'react'
import Airplanes2 from '../assets/img/airplanes2.svg'
import CityNight from '../assets/img/city-night.svg'
import CityNight2 from '../assets/img/city-night-2.svg'
import CityNight3 from '../assets/img/city-night-3.svg'
import CityNight4 from '../assets/img/city-night-4.svg'
import CityNight5 from '../assets/img/city-night-5.svg'
import CityNight6 from '../assets/img/city-night-6.svg'
import CityNight7 from '../assets/img/city-night-7.svg'
import Button from '../components/Button'
import { useNavigate } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import {Context} from '../Context'
import Steps from '../components/Steps'
import Plane3 from '../assets/img/plane-3.svg'
import Plane4 from '../assets/img/plane-4.svg'

const Nights = () => {
    const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const [name, setName, email, setEmail, city, setCity, month, setMonth, nights, setNights, hotelClass, setHotelClass, colleagues, setColleagues, additionalServices, setAdditionalServices] = useContext(Context)
    const navigate = useNavigate()
    const [selected, setSelected] = useState(nights)
    const styles = {
        logo: {
            margin: '3%',
            marginBottom: '1%',
            alignSelf: 'flex-end',
            width: '10%'
        },
        titleMobile: {
            position: 'absolute',
            top: '3%',
            width: '90%',
        }
    };

    const handleIllustrations = (month) => {
        switch (month) {
            case 1:
                return CityNight
            case 2:
                return CityNight2
            case 3:
                return CityNight3
            case 4:
                return CityNight4
            case 5:
                return CityNight5
            case 6:
                return CityNight6
            case 7:
                return CityNight7
            default:
                return CityNight7
        }
    }

    const handleSelect = (value) => {
        setSelected(value)
        setNights(value)
    }

    const onSubmit = async () => {
        if (nights) {
            navigate('/select-accommodation')
        }
    }

    useEffect(() => {
        setNights(nights)
    }, [])

    useEffect(() => {
        if (!name || !email || !city || !month) {
            navigate('/')
        }
    }, [])

    return (
        <Grid container style={{
            height: mdDown ? '100%' : '100vh',
            minHeight: '100vh',
            overflow: 'hidden',
        }}>
            <Grid item md={7} xs={12} style={{ backgroundColor: '#EFC2CB', height: mdDown ? '40vh' : '100vh', display: 'flex', justifyContent: 'center', overflow: 'hidden' }}>
                <CSSTransition
                    key={selected}
                    classNames='fade'
                    in={true}
                    appear={true}
                    timeout={200}
                >
                    <img src={handleIllustrations(selected)} alt='Night Illustration' style={{ width: '105%', marginLeft: '7%' }} />
                </CSSTransition>
                {mdDown && 
                <Typography variant="h2" component="h2" gutterBottom style={styles.titleMobile}>
                    How many nights do you have in mind?
                </Typography>
                }
            </Grid>
            <Grid item md={5} xs={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', minHeight: mdDown ? '60vh' : '100vh' }}>
                {!mdDown && <img src={Logo} alt='Offsiteio Logo' style={styles.logo} />}
                <Steps mdDown={mdDown} step={3} />
                <Box style={{ width: mdDown ? '90%' : '70%', display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: '5%', height: mdDown ? 'auto' : '60vh', minHeight: mdDown ? 0 : '60vh', zIndex: 5 }}>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                        {!mdDown && <Typography variant="h3" component="h3" gutterBottom>
                            How many nights do you have in mind?
                        </Typography>}
                    </Box>
                    <Box sx={{ width: '100%', mt: '7%', mb: '4%' }}>
                        <Slider defaultValue={nights} aria-label="Default" valueLabelDisplay="on" min={1} max={7} onChange={(e) => handleSelect(e.target.value)} />
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant="h3" component="h3" gutterBottom>
                                1
                            </Typography>
                            <Typography variant="h3" component="h3" gutterBottom>
                                7
                            </Typography>
                        </Box>
                    </Box>
                    <Button
                        text='Continue'
                        styles={{
                            marginTop: '7%',
                            width: '100%',
                            height: '3.5rem',
                            minHeight: '3.5rem'
                        }}
                        onSubmit={onSubmit}
                    />
                </Box>
                <img src={Airplanes2} alt='Airplanes Illustration' className="zoom-in-zoom-out" style={{ width: '100%', position: 'relative', bottom: 0 }} />
                <img src={Plane3} alt='plane illustration' className='plane' width={40} style={{position: 'absolute', bottom: mdDown ? '7%' : '11%', right: mdDown ? '70%' : '30%'}} />
                <img src={Plane4} alt='plane illustration' className='plane-2' width={40} style={{position: 'absolute', bottom: mdDown ? '6%' : '14%', right: mdDown ? '17%' : '10%'}} />
            </Grid>
        </Grid>
    )
}

export default Nights