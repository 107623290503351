import { useTheme, Button, useMediaQuery } from '@mui/material'

const Pill = ({ text, onSelect, defaultValue, selected, icon, fontSize, w100, lineHeight }) => {
    const theme = useTheme();
    const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));
    return (
        <Button
            size="small"
            variant={selected ? "contained" : "text"}
            color="primary"
            sx={{
                height: '40px',
                width: w100 ? '100%' : '30%',
                alignSelf: 'center',
                color: selected ? 'white' : 'black',
                fontWeight: defaultValue || selected ? 'bold' : 'normal',
                fontSize: fontSize,
                lineHeight: lineHeight,
                letterSpacing: defaultValue || selected ? 0.2 : 0.4,
                marginBottom: 0,
                backgroundColor: `${selected ? theme.colors.primary : theme.colors.grey.main}`,
                '&:hover': {
                    backgroundColor: `${theme.colors.primary.main}`,
                    color: `${theme.palette.getContrastText(
                        theme.colors.primary.main
                    )}`
                }
            }}
            onClick={() => onSelect(text)}
        >
            {text}
            {icon &&
                <img
                    src={icon}
                    alt='icon'
                    style={{
                        height: '20px',
                        width: 'auto',
                        marginLeft: '5px',
                    }} />}
        </Button>
    )
}

export default Pill