import { Box, Grid, Typography, useMediaQuery, FormControl, FormControlLabel, RadioGroup, FormLabel, Radio } from '@mui/material'
import Logo from '../assets/logo/offsiteio-logo.svg'
import { useState, useContext, useEffect } from 'react'
import Airplanes2 from '../assets/img/airplanes2.svg'
import ColleaguesIllustration from '../assets/img/colleagues.svg'
import ColleaguesIllustration2 from '../assets/img/colleagues-2.svg'
import ColleaguesIllustration3 from '../assets/img/colleagues-3.svg'
import ColleaguesIllustration4 from '../assets/img/colleagues-4.svg'
import ColleaguesIllustration5 from '../assets/img/colleagues-5.svg'
import Button from '../components/Button'
import { useNavigate } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import { Context } from '../Context'
import Pill from '../components/Pill'
import Steps from '../components/Steps'
import Plane3 from '../assets/img/plane-3.svg'
import Plane4 from '../assets/img/plane-4.svg'

const colleaguesQuantity = [
    "25 or less",
    "26 - 50",
    "51 - 75",
    "76 - 100",
    "More than 100"
]

const Colleagues = () => {
    const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const [name, setName, email, setEmail, city, setCity, month, setMonth, nights, setNights, hotelClass, setHotelClass, colleagues, setColleagues, additionalServices, setAdditionalServices] = useContext(Context)
    const navigate = useNavigate()
    const [selected, setSelected] = useState(colleagues ? colleagues : colleaguesQuantity[0])
    const styles = {
        logo: {
            margin: '3%',
            marginBottom: '1%',
            alignSelf: 'flex-end',
            width: '10%'
        },
        titleMobile: {
            position: 'absolute',
            top: '3%',
            width: '90%',
        }
    };

    const handleIllustrations = (colleagues) => {
        switch (colleagues) {
            case "25 or less":
                return ColleaguesIllustration
            case "26 - 50":
                return ColleaguesIllustration2
            case "51 - 75":
                return ColleaguesIllustration3
            case "76 - 100":
                return ColleaguesIllustration4
            case "More than 100":
                return ColleaguesIllustration5
            default:
                return ColleaguesIllustration
        }
    }

    const handleSelect = (value) => {
        setSelected(value)
        setColleagues(value)
    }

    const onSubmit = async () => {
        if (colleagues) {
            navigate('/additional-services')
        }
    }

    useEffect(() => {
        setColleagues(selected)
    }, [])

    useEffect(() => {
        if (!name || !email || !city || !month || !nights || !hotelClass) {
            navigate('/')
        }
    }, [])

    return (
        <Grid container style={{
            height: mdDown ? '100%' : '100vh',
            minHeight: '100vh',
            overflow: 'hidden',
        }}>
            <Grid item md={7} xs={12} style={{ backgroundColor: '#EFC2CB', height: mdDown ? '40vh' : '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center', overflow: 'hidden' }}>
                <CSSTransition
                    key={selected}
                    classNames='fade'
                    in={true}
                    appear={true}
                    timeout={200}
                >
                    <img src={handleIllustrations(selected)} alt='Colleagues Illustration' style={{ width: mdDown && (selected !== '76 - 100' && selected !== 'More than 100') ? '50%' : '90%', paddingBottom: mdDown ? 0 : '1vh', height: 'auto', maxHeight: selected !== 'More than 100' ? '50vh' : '100vh' }} />
                </CSSTransition>
                {mdDown && 
                <Typography variant="h2" component="h2" gutterBottom style={styles.titleMobile}>
                    How many colleagues are planning on attending?
                </Typography>
                }
            </Grid>
            <Grid item md={5} xs={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', minHeight: mdDown ? '60vh' : '100vh' }}>
                {!mdDown && <img src={Logo} alt='Offsiteio Logo' style={styles.logo} />}
                <Steps mdDown={mdDown} step={5} />
                <Box style={{ width: mdDown ? '90%' : '70%', display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: '5%', height: mdDown ? '30vh' : '60vh', minHeight: mdDown ? 0 : '60vh', zIndex: 5 }}>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                        {!mdDown && <Typography variant="h3" component="h3" gutterBottom>
                            How many colleagues are planning on attending?
                        </Typography>}
                        <Typography gutterBottom>
                            Your best estimate works fine!
                        </Typography>
                    </ Box>
                    <Box sx={{ m: 5, mb: 0, mt: 1, width: '100%', display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                        {colleaguesQuantity.map(qty => <div style={{ width: '30%', marginRight: '3.33%', marginBottom: '2%' }}><Pill fontSize={'0.8rem'} text={qty} onSelect={handleSelect} selected={qty === selected} w100 /></div>)}
                    </Box>
                    <Button
                        text='Continue'
                        styles={{
                            marginTop: '7%',
                            width: '100%',
                            height: '3.5rem',
                            minHeight: '3.5rem'
                        }}
                        onSubmit={onSubmit}
                    />
                </Box>
                <img src={Airplanes2} alt='Airplanes Illustration' className="zoom-in-zoom-out" style={{ width: '100%', position: 'relative', bottom: 0 }} />
                <img src={Plane3} alt='plane illustration' className='plane' width={40} style={{position: 'absolute', bottom: mdDown ? '7%' : '11%', right: mdDown ? '70%' : '30%'}} />
                <img src={Plane4} alt='plane illustration' className='plane-2' width={40} style={{position: 'absolute', bottom: mdDown ? '6%' : '14%', right: mdDown ? '17%' : '10%'}} />
            </Grid>
        </Grid>
    )
}

export default Colleagues