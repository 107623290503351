import { useState, createContext } from 'react'
export const Context = createContext(null)

export const ContextProvider = props => {
    const [name, setName] = useState(null)
    const [email, setEmail] = useState(null)
    const [city, setCity] = useState([])
    const [month, setMonth] = useState(null)
    const [nights, setNights] = useState(2)
    const [hotelClass, setHotelClass] = useState(null)
    const [colleagues, setColleagues] = useState(null)
    const [additionalServices, setAdditionalServices] = useState([])

    return (
        <Context.Provider value={[
            name, setName,
            email, setEmail,
            city, setCity,
            month, setMonth,
            nights, setNights,
            hotelClass, setHotelClass,
            colleagues, setColleagues,
            additionalServices, setAdditionalServices
        ]}
        >
            {props.children}
        </Context.Provider>
    )
}
