import { Button as Btn, CircularProgress } from '@mui/material'

const Button = ({ text, styles, onSubmit, loading, type }) => {
    return (
        <Btn
            variant="contained"
            sx={styles}
            color="primary"
            onClick={onSubmit}
            type={type}
        >
            {!loading ? text : <CircularProgress size="1.6rem" sx={{color: 'white'}} />}
        </Btn>
    )
}

export default Button