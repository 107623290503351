import { Box, Grid, Typography, Paper, useMediaQuery, TextField, InputAdornment } from '@mui/material'
import EmailIcon from '@mui/icons-material/Email';
import CityImage from '../assets/img/city.jpg'
import BeerImage from '../assets/img/beer.jpg'
import MiamiImage from '../assets/img/miami.jpg'
import BarImage from '../assets/img/bar.jpg'
import Logo from '../assets/logo/offsiteio-logo.svg'
import Carousel from 'react-material-ui-carousel'
import { useState, useContext } from 'react'
import Airplanes from '../assets/img/airplanes.svg'
import Airplanes2 from '../assets/img/airplanes2.svg'
import { Context } from '../Context'
import Button from '../components/Button'
import { useNavigate } from 'react-router-dom'
import { showToast } from '../utils/showToast'
import axios from 'axios'
import Plane from '../assets/img/plane.svg'
import Plane2 from '../assets/img/plane-2.svg'
import Plane3 from '../assets/img/plane-3.svg'
import Plane4 from '../assets/img/plane-4.svg'

const Home = () => {
    const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'))
    const [name, setName, email, setEmail, city, setCity, month, setMonth, nights, setNights, hotelClass, setHotelClass, colleagues, setColleagues, additionalServices, setAdditionalServices] = useContext(Context)
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const styles = {
        paperContainer: {
            height: mdDown ? '40vh' : '100vh',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: '0px',
        },
        backgroundImage1: {
            backgroundImage: `url(${CityImage})`,
        },
        backgroundImage2: {
            backgroundImage: `url(${BeerImage})`,
        },
        backgroundImage3: {
            backgroundImage: `url(${MiamiImage})`,
        },
        backgroundImage4: {
            backgroundImage: `url(${BarImage})`,
        },
        logo: {
            marginTop: mdDown ? '2vh' : '5vh',
        }
    };

    const checkEmail = (mail) => {
        const regex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        if (mail.match(regex)) {
            setLoading(true)
            const url = 'https://script.google.com/macros/s/AKfycbw6_yMVWGF7zwcip_LgAc9jS9FGoC3PlJ7XCxgdGE5VIMo_049xeon44TbJQhtggqg03g/exec'
            let date = new Date();
            let datestring = ("0" + (date.getMonth() + 1).toString()).substr(-2) + "/" + ("0" + date.getDate().toString()).substr(-2)  + "/" + (date.getFullYear().toString()).substr(2);
            let formData = new FormData();
            formData.append('date', datestring);
            formData.append('name', name);
            formData.append('email', email);

            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }

            axios.post(url, formData, config)
                .then((response) => {
                    if (response.status === 200) {
                        setLoading(false)
                        navigate('/select-city')
                    } else {
                        setLoading(false)
                        showToast('error', 'Something went wrong, please try again')
                    }
                })
                .catch(error => {
                    console.error(error)
                    setLoading(false)
                    showToast('error', 'Something went wrong, please try again')
                }
                )
        } else {
            showToast('error', 'Please enter a valid email address')
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        if (name && email) {
            checkEmail(email)
        } else {
            showToast('error', 'Please type your name and email to continue')
        }
    }

    return (
        <Grid container style={{
            height: mdDown ? '100%' : '100vh',
            minHeight: '100vh',
            overflow: 'hidden',
        }}>
            <Grid item md={7} xs={12} style={{ backgroundColor: '#F04B6C', height: mdDown ? '40vh' : '100vh' }}>
                <Carousel
                    autoPlay
                    infiniteLoop
                    showThumbs={false}
                    indicators={false}
                    interval={5000}
                >
                    <Paper style={Object.assign({}, styles.paperContainer, styles.backgroundImage1)}>
                        <img src={Logo} alt='Offsiteio Logo' width={mdDown ? 80 : 150} height={mdDown ? 80 : 150} />
                    </Paper>
                    <Paper style={Object.assign({}, styles.paperContainer, styles.backgroundImage2)}>
                        <img src={Logo} alt='Offsiteio Logo' width={mdDown ? 80 : 150} height={mdDown ? 80 : 150} />
                    </Paper>
                    <Paper style={Object.assign({}, styles.paperContainer, styles.backgroundImage3)}>
                        <img src={Logo} alt='Offsiteio Logo' width={mdDown ? 80 : 150} height={mdDown ? 80 : 150} />
                    </Paper>
                    <Paper style={Object.assign({}, styles.paperContainer, styles.backgroundImage4)}>
                        <img src={Logo} alt='Offsiteio Logo' width={mdDown ? 80 : 150} height={mdDown ? 80 : 150} />
                    </Paper>
                </Carousel>
            </Grid>
            <Grid item md={5} xs={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                {!mdDown && 
                <>
                    <img src={Airplanes} alt='Airplanes Illustration' className="zoom-in-zoom-out" style={{ width: '100%', marginTop: '-75px' }} />
                    <img src={Plane} alt='plane illustration' className='plane' width={40} style={{position: 'absolute', top: '5%', right: '30%'}} />
                    <img src={Plane2} alt='plane illustration' className='plane-2' width={40} style={{position: 'absolute', top: '4%', right: '10%'}} />
                </>
                }
                
                <Box style={{ width: mdDown ? '90%' : '70%', marginTop: mdDown ? 15 : 0, display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: mdDown ? '0%' : '20%' }}>
                    {!mdDown &&
                        <Typography variant="h4" component="h4" gutterBottom>
                            Let us help you every step of the way...
                        </Typography>
                    }
                    <Typography variant="p" component="p" gutterBottom>
                        We need to ask you a few questions to better understand your needs.
                    </Typography>
                </Box>

                <Box style={{ width: mdDown ? '90%' : '70%', height: mdDown ? 'auto' : '170px', display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: '5%', position: mdDown ? 'static' : 'relative', bottom: '15vh', zIndex: 5 }}>
                    <Box sx={{ width: '100%', display: 'flex' }}>
                        <Typography variant="h3" component="h3" gutterBottom>
                            What's your name and work email?
                        </Typography>
                    </Box>
                    <form
                        method='POST'
                        action='https://script.google.com/macros/s/AKfycbw6_yMVWGF7zwcip_LgAc9jS9FGoC3PlJ7XCxgdGE5VIMo_049xeon44TbJQhtggqg03g/exec'
                        onSubmit={onSubmit}
                        style={{ width: '100%' }}
                    >
                        <TextField
                            size="medium"
                            sx={{ marginTop: '7%' }}
                            fullWidth={true}
                            onChange={e => setName(e.target.value)}
                            value={name}
                            name='name'
                            placeholder={'Your Full Name'}
                        />
                        <TextField
                            size="medium"
                            sx={{ marginTop: '7%' }}
                            fullWidth={true}
                            onChange={e => setEmail(e.target.value)}
                            value={email}
                            name='email'
                            placeholder={'example@workmail.com'}
                            type='email'
                        />
                        <Button
                            text='Continue'
                            styles={{
                                marginTop: '7%',
                                width: '100%',
                                height: '3.5rem',
                                minHeight: '3.5rem'
                            }}
                            loading={loading}
                            type='submit'
                        />
                    </form>
                </Box>
                <Box />
                <img src={Airplanes2} alt='Airplanes Illustration' className="zoom-in-zoom-out" style={{ width: '100%', position: 'relative', bottom: 0 }} />
                <img src={Plane3} alt='plane illustration' className='plane' width={40} style={{position: 'absolute', bottom: mdDown ? '7%' : '11%', right: mdDown ? '70%' : '30%'}} />
                <img src={Plane4} alt='plane illustration' className='plane-2' width={40} style={{position: 'absolute', bottom: mdDown ? '6%' : '14%', right: mdDown ? '17%' : '10%'}} />
            </Grid>
        </Grid>
    )
}

export default Home